<template>
  <p :class="customStyle" class="font-size-sm d-flex align-items-start">
    <i class="icon-info22 mr-1" style="margin-top: 0.2em;"></i>
    <span class="d-block">
      <slot>
        {{ $t(text) }}
      </slot>
    </span>
  </p>
</template>

<script>
export default {
  name: "InfoNote",
  props: {
    text: {
      type: String,
      default: ""
    },
    customStyle: {
      type: String,
      default: "text-muted",
    }
  },
}
</script>

<style scoped>
</style>
