<template>
  <ConfirmationModal :with-prefix="false" @closemodal="closeConfirmation" @confirmmodal="confirmed" v-show="needsConfirmation" :action="$t(confirmationType)" />
  <EnGrossItemModal v-if="selectedOffer" :item="selectedOffer" :farmer="stockBatch.getFarm()" :products="products" @closemodal="refresh" @update="refresh"/>
  <div class="verde-farmer-top with-sticky-footer-buttons">
    <Card class="card-header-pb-0 card-title-mb-0 card-msg-pt-0" :with-footer="false" :title="$t('frontoffice.farmers.offers.en-gross.title',{farmer: farmer.getName()})" />
    <div class="pb-2">
      <div class="card p-2 text-center bg-verde">
        <div class="row text-center justify-content-center">
          <div class="col-sm-5 col-md-3 d-block align-self-center">
            <h2 class="mb-0 font-weight-semibold">{{ $t('frontoffice.farmers.offers.en-gross.active_offers_title') }}</h2>
            <!-- <p class="mb-0" v-if="stockBatch.isTypeOffers()">{{ stockBatch.getCustomerName() }}</p> -->
          </div>
        </div>
      </div>
      <div v-if="hasOffers">
        <EnGrossItemCard v-for="(item) in offers" :key="item.getId()" :item="item" @update="showUpdateItemModal" @delete="askConfirmation" />
      </div>
      <div class="d-block w-100 text-center mt-5 mb-5" v-else>
        <p>{{$t('frontoffice.farmers.offers.en-gross.no_offers')}} !</p>
      </div>
    </div>
    <div class="add-new-offer-container">
      <Button class="w-100" @click.stop="createNewOffer" name="frontoffice.farmers.offers.en-gross.button.add_new_offer" type="add-mb-0" />
    </div>
    <StickyBottom>
      <Button class="w-100" @click.stop="sendStockBatchToDone()" :name="sendButtonName" type="confirm-mb-0" />
    </StickyBottom>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import {DatesMixin} from "@/mixins/GeneralMixin";
import Button from "@/components/elements/Button";
import StickyBottom from "@/components/elements/StickyBottom";
import {LoadingModalMixin, ConfirmationModalMixin} from "@/mixins/ModalMixin";
import EnGrossItemCard from "@/components/engross/frontoffice/EnGrossItemCard";
import EnGrossItemModal from "@/components/engross/frontoffice/EnGrossItemModal";
import ConfirmationModal from "@/components/elements/ConfirmationModal";
import EnGrossProduct from "@/entities/EnGrossProduct";
import {ScrollMixin} from "@/mixins/ViewMixin";
import StockBatch from "@/entities/StockBatch";

export default {
  name: "OfferEnGross",
  mixins: [DatesMixin, LoadingModalMixin, ConfirmationModalMixin, ScrollMixin],
  emits: ['justProcessed', 'update'],
  components: {ConfirmationModal, EnGrossItemModal, EnGrossItemCard, StickyBottom, Button, Card},
  props: {
    offers: {
      type: Array,
    },
    stockBatch: {
      type: Object,
    }
  },
  data: function () {
    return {
      selectedOffer: null,
      products: [],
    }
  },
  beforeMount: function () {
    this.getFarmerProducts();
  },
  updated() {
    this.scrollToClassElement('add-new-offer-container');
  },
  computed: {
    farmer: function () {
      return this.stockBatch.getFarm();
    },
    hasOffers: function () {
      return this.offers.length > 0;
    },
    sendButtonName: function () {
      return 'frontoffice.farmers.offers.en-gross.button.back_to_dashboard';
    },
    hasChanges() {
      return true;
    }
  },
  methods: {
    refresh: function () {
      if (this.isLoading) {
        return;
      }
      this.$nextTick(() => {
        this.selectedOffer = null;
        this.$emit("update", false, true);
      })
    },
    showUpdateItemModal: function (item) {
      this.selectedOffer = item;
    },
    createNewOffer: function () {
      this.selectedOffer = new EnGrossProduct();
    },
    getFarmerProducts: function () {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getProductsForEnGross(this.farmer.getId())).then(
          response => {
            let content = response.data
            that.products = content.data;
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
    confirmed: function () {
      switch (this.confirmationType) {
        case "frontoffice.farmers.offers.en-gross.confirmation.delete_item":
          this.deleteItem(this.confirmedEvent.getPayload())
          break;
        default:
          break;
      }
      this.closeConfirmation();
    },
    deleteItem: function (item) {
      let that = this;
      this.queueJob();
      this.axios.delete(this.$store.state.config.getEnGrossProductUri(this.farmer.getId(), item.getId()))
          .finally(function () {
            that.completeJob();
            that.refresh();
          });
    },
    updateItem: function () {
      this.selectedOffer = null;
    },
    sendStockBatchToDone: function () {
      let that = this;
      this.queueJob();
      this.axios.put(this.$store.state.config.getSendToDoneStockBatchUri(this.$route.params.batchId)).then(
          response => {
            let content = response.data;
            that.batch = new StockBatch(content.data);
            that.wasJustProcessed = true;
            that.$emit('update', that.batch);
            this.$router.push({name: 'frontoffice.breadcrumb.farmers.offers.dashboard', params:{'id': this.$route.params.id, 'batchId': this.$route.params.batchId}});
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
  }
}
</script>

<style scoped>
::v-deep(.v3dp__datepicker .v3dp__popout) {
  position: absolute !important;
  left: -65.08px;
}

.add-new-offer-container {
  margin-bottom: 60px;
}

@media (min-width: 768px) {

}
</style>
