<template>
  <Modal class="en-gross" :with-footer="true" :with-block-footer="!isScreenLgWidth" :dismissible="true" :custom-name="!offer.isNew() ? getProductName(offer.getProductId()) : $t('frontoffice.farmers.offers.en-gross.modal-title')">
    <template v-slot:default>
      <div class="row mb-2" v-if="offer.isNew()">
        <div class="col-12">
          <div class="align-items-center">
            <SelectInput :class="{'error':v$.offer.product.product_id.$error}" :reference="offer" :options="productValues" @input="updateProduct"
                         :selected="offer.getProductId()"/>
          </div>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.offer.product.product_id.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.availability')}}</div>
        <div class="col-12 col-lg-9">
          <div class="d-flex align-items-center mb-2 mb-lg-0 d-lg-inline-flex w-lg-50">
            <div class="col-3"><span>{{ $t('frontoffice.farmers.offers.en-gross.item.valid_from') }}</span></div> <DatePicker :class="{'error':v$.offer.product.valid_from.$error}" inputFormat="dd-MM-yyyy" v-model="startAt" :lowerLimit="today" :clearable="true" class="ml-lg-2 d-lg-inline w-75" :placeholder="$t('frontoffice.farmers.offers.en-gross.item.validity_placeholder')" />
          </div>
          <div class="d-flex align-items-center d-lg-inline-flex w-lg-50">
            <div class="col-3 col-lg-2 text-lg-center"><span>{{ $t('frontoffice.farmers.offers.en-gross.item.valid_to') }}</span></div> <DatePicker :class="{'error':v$.offer.product.valid_to.$error}" inputFormat="dd-MM-yyyy" :key="startAt" :disabled="!startAt" :lowerLimit="startAt" :clearable="true" v-model="endAt" type="text" class="ml-lg-2 d-lg-inline w-75" :placeholder="$t('frontoffice.farmers.offers.en-gross.item.validity_placeholder')"/>
          </div>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.offer.product.valid_to.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.offered_qty')}}</div>
        <div class="col-12 col-lg-9">
          <div class="align-items-center">
            <SelectInput :class="{'error':v$.offer.product.qty.$error}" :reference="offer" :options="qtyValues" @input="updateQty"
                         :selected="offer.getQty()"/>
            </div>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.offer.product.qty.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.min_qty')}}</div>
        <div class="col-12 col-lg-9">
          <div class="align-items-center">
            <SelectInput :class="{'error':v$.offer.product.min_qty.$error}" :reference="offer" :options="qtyValues" @input="updateMinQty"
                         :selected="offer.getMinQty()"/>
          </div>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.offer.product.min_qty.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.price', {currency: offer.getCurrency(), unit: offer.getUnit()})}}</div>
        <div class="col-12 col-lg-9">
          <div class="align-items-center">
            <TextInput pattern="\([0-9]*[.])?[0-9]+" type="number" :class="{'error':v$.offer.product.price.$error}" :value="offer.getPrice()"
                       :reference="offer" @input="updatePrice"/>
          </div>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.offer.product.price.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>

      <div class="mt-4 mb-2" v-if="farmer.showCommissionDetails()">
        <InfoNote>
          {{ $t('frontoffice.farmers.offers.en-gross.item.commission_info', {percent: 8}) }}
          <br />
          {{$t('frontoffice.farmers.offers.en-gross.item.transport_info')}}
          <span class="d-block text-info-600" v-html="$t('frontoffice.farmers.offers.en-gross.item.commission_info_example', {price: formatPrice(offer.getPrice()-offer.getPrice() * 0.08, offer.getCurrency()), unit: offer.getUnit()})"></span>
        </InfoNote>
      </div>

      <div class="row mt-4 mb-2 border-bottom-2">
        <div class="w-100">
          <h3 class="m-0">{{$t('frontoffice.farmers.offers.en-gross.item.packing_transport_details')}}</h3>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.packing')}}</div>
        <div class="col-12 col-lg-9">
          <div class="align-items-center">
            <SelectInput :reference="offer" :options="packingValues" @input="updatePacking"
                         :selected="offer.getPacking()"/>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-lg-3 mt-lg-auto mb-lg-auto mb-2">{{$t('frontoffice.farmers.offers.en-gross.item.delivery_area')}}</div>
        <div class="col-12 col-lg-9">
          <div class="d-lg-flex align-items-center">
            <CheckboxInput class="d-lg-inline-flex d-block" v-for="(deliveryArea) in deliveryAreaValues" :key="deliveryArea" :label="deliveryArea.txt" :value="offer.getDeliveryAreas().includes(deliveryArea.val)" @update="updateDeliveryArea(offer, deliveryArea.val)"/>
          </div>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.offer.product.details.delivery_areas.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-9 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.euro_pallet')}}?</div>
        <div class="col-3 col-lg-9">
          <div class="align-items-center">
            <SelectInput :reference="offer" :options="yesNoValues" @input="updateEuroPallet" :selected="offer.hasDeliveryEuroPallet()"/>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-9 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.transport')}}?</div>
        <div class="col-3 col-lg-9">
          <div class="align-items-center">
            <SelectInput :reference="offer" :options="yesNoValues" @input="updateDeliveryCooler" :selected="offer.hasDeliveryCooler()"/>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-2 border-bottom-2">
        <div class="w-100">
          <h3 class="m-0">{{$t('frontoffice.farmers.offers.en-gross.item.product_details')}}</h3>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.diameter')}}</div>
        <div class="col-12 col-lg-9">
          <div class="d-flex align-items-center mb-2 mb-lg-0 d-lg-inline-flex w-lg-50">
            <div class="col-3"><span>{{ $t('frontoffice.farmers.offers.en-gross.item.diameter_from') }}</span></div> <SelectInput class="col-9 d-inline" :class="{'error':v$.offer.product.details.diameter_from.$error}" :reference="offer" :options="diameterValues" @input="updateDiameterFrom"
                         :selected="offer.getDiameterFrom()"/>
          </div>
          <div class="d-flex align-items-center d-lg-inline-flex w-lg-50">
            <div class="col-3 col-lg-2 text-lg-center"><span>{{ $t('frontoffice.farmers.offers.en-gross.item.diameter_to') }}</span></div> <SelectInput class="col-9 col-lg-10 d-inline" :class="{'error':v$.offer.product.details.diameter_to.$error}" :reference="offer" :options="diameterValues" @input="updateDiameterTo"
                         :selected="offer.getDiameterTo()"/>
          </div>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.offer.product.details.diameter_from.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
        <div class="input-errors" v-for="(error, index) of v$.offer.product.details.diameter_to.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <UploadFileDragDropMultiple
            class="col-12"
            :class="{'error': v$.image.$error}"
            v-model="image"
            name="invoices"
            :disabled="isLoading"
            :multiple="false"
            :label="''"
            :accepted-files="'.jpg,.jpeg,.png,.gif'"
            placeholder="frontoffice.farmers.offers.en-gross.item.image.label"
            files-list-label="frontoffice.farmers.offers.en-gross.item.image.listing"
            empty-message="frontoffice.farmers.offers.en-gross.item.image.empty">
        </UploadFileDragDropMultiple>
        <div class="input-errors" v-for="(error, index) of v$.image.$errors" :key="index">
          <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-lg-3 mt-lg-auto mb-lg-auto">{{$t('frontoffice.farmers.offers.en-gross.item.certificates')}}</div>
        <div class="col-12 col-lg-9">
          <div class="align-items-center">
            <TextAreaInput class="w-100" :reference="offer" :value="offer.getCertificates()" @input="updateCertificates" :placeholder="$t('frontoffice.farmers.offers.en-gross.item.certificates_info')" />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <InfoNote custom-style="text-danger">
          {{ $t('frontoffice.farmers.offers.en-gross.item.offer_info') }}
          <br class="clearfix" />
          {{ $t('frontoffice.farmers.offers.en-gross.item.offer_info_2')}}
        </InfoNote>
      </div>

      <div class="form-group notification-box">
        <NotificationAlert v-show="canShowNotification" :notifications="notification"/>
      </div>


    </template>
    <template v-slot:footer>
      <div class="mt-2 mr-auto">
        <div class="d-inline-block w-100 w-lg-auto"><Button :disabled="!isFormReady() || isLoading" class="w-100 w-lg-auto" type="confirm" :name="actionButtonTitle" @click="update" /></div>
        <Spinner class="ml-2 d-none d-lg-inline-block" v-if="isLoading" :simple="true" />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/elements/Modal";
import Button from "@/components/elements/Button";
import {NumbersMixin, ScreenSizeMixin, ValidationsMixin, DatesMixin, NotificationsMixin} from "@/mixins/GeneralMixin";
import SelectInput from "@/components/elements/SelectInput";
import TextAreaInput from "@/components/elements/TextAreaInput";
import TextInput from "@/components/elements/TextInput";

import useVuelidate from '@vuelidate/core'
import { required, decimal, minValue } from '@vuelidate/validators'
import InfoNote from "@/components/elements/InfoNote";
import EnGrossProduct from "@/entities/EnGrossProduct";
import CheckboxInput from "@/components/elements/CheckboxInput";
import DatePicker from "vue3-datepicker";
import DatePickerMixin from "@/mixins/DatePickerMixin";
import NotificationAlert from "@/components/elements/NotificationAlert.vue";
import Spinner from "@/components/elements/Spinner.vue";
import UploadFileDragDropMultiple from "@/components/elements/UploadFileDragDropMultiple.vue";
import {ScrollMixin} from "@/mixins/ViewMixin";

const diameterToGteDiameterFromValidator = (value, vm) => vm.offer.product.details.diameter_from <= value;
const qtyGteMinQtyValidator = (value, vm) => vm.offer.product.min_qty <= value;

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "EnGrossItemModal",
  emits: ['update'],
  mixins: [ScreenSizeMixin, ValidationsMixin, NumbersMixin, DatesMixin, DatePickerMixin, NotificationsMixin, ScrollMixin],
  components: {
    Spinner,
    UploadFileDragDropMultiple,
    CheckboxInput, TextInput, TextAreaInput, SelectInput, Button, Modal, InfoNote, DatePicker, NotificationAlert},
  watch: {
    startAt: {
      handler: function (newValue, oldValue) {
        if (oldValue != null) {
          this.endAt = null;
        }
        this.v$.offer.product.valid_from.$reset();
      }
    },
    endAt: {
      handler: function () {
        this.v$.offer.product.valid_to.$reset();
      }
    },
    "offer.product.details.diameter_from": {
      handler: function (value) {
        if (value === 0) {
          this.offer.product.details.diameter_to = 0;
        }
      }
    }
  },
  validations: {
    image: {
      required
    },
    offer: {
      product: {
        product_id: {
          required,
        },
        qty: {
          required,
          decimal,
          qtyMinValue: minValue(0.01),
          qtyGteMinQty: qtyGteMinQtyValidator
        },
        min_qty: {
          required,
          decimal,
          minQtyMinValue: minValue(0.01),
        },
        price: {
          required,
          decimal,
          priceMinValue: minValue(0.01),
        },
        valid_from: {
          required,
        },
        valid_to: {
          required,
        },
        details: {
          delivery_areas: {
            required,
          },
          diameter_from: {
            decimal,
          },
          diameter_to: {
            decimal,
            diameterToGteDiameterFrom: diameterToGteDiameterFromValidator
          },
        }
      }
    },
  },
  props: {
    item: Object,
    products: Array,
    farmer: Object
  },
  data: function () {
    return {
      offer: new EnGrossProduct(),
      startAt: null,
      endAt: null,
      today: new Date(),
      isLoading: false,
      farmerId: 0,
      image: [],
    }
  },
  beforeMount() {
    this.offer = this.item;
    this.farmerId = this.$route.params.id;

    if (!this.offer.isNew()) {
      this.startAt = new Date(this.offer.getValidFrom());
      this.endAt = new Date(this.offer.getValidTo());
      this.today = this.startAt;
      if (this.offer.getImage()) {
        this.image = [this.offer.getImage()];
      }
    }
  },
  computed: {
    actionButtonTitle: function () {
      return this.item.isNew() ? 'frontoffice.farmers.offers.en-gross.button.add_offer' : 'frontoffice.farmers.offers.en-gross.button.update_offer';
    },
    productValues: function () {
      let values = [
        {
          val: "",
          txt: this.$t("frontoffice.farmers.offers.en-gross.item.select_product")
        }
      ];
      this.products.forEach(function (product) {
        values.push({
          val: product.id,
          txt: product.display_name ?? product.name,
        });
      });
      return values;
    },
    packingValues: function () {
      let values = [
        {
          val: 0,
          txt: this.$t("frontoffice.farmers.offers.en-gross.item.no_packing_value"),
        },
        {
          val: 5,
          txt: this.$t("frontoffice.farmers.offers.en-gross.item.packing_value", {qty: 5, unit: this.offer.getUnit()}),
        }
      ];
      for (let i = 10; i<=100; i+=10) {
        values.push({
          val: i,
          txt: this.$t("frontoffice.farmers.offers.en-gross.item.packing_value", {qty: i, unit: this.offer.getUnit()}),
        });
      }

      return values
    },
    deliveryAreaValues: function () {
      return [
        {
          val: "max_100km",
          txt: this.$t("frontoffice.farmers.offers.en-gross.item.delivery.max_100km"),
        },
        {
          val: "romania",
          txt: this.$t("frontoffice.farmers.offers.en-gross.item.delivery.romania"),
        },
        {
          val: "eu",
          txt: this.$t("frontoffice.farmers.offers.en-gross.item.delivery.eu"),
        },
      ];
    },
    diameterValues: function () {
      let values = [{
        val: 0,
        txt: this.$t("frontoffice.farmers.offers.en-gross.item.no_diameter_value")
      }];

      for (let i = 10; i<100; i+=5) {
        values.push({
          val: i,
          txt: i + "mm"
        });
      }
      for (let i = 100; i<=500; i+=10) {
        values.push({
          val: i,
          txt: i + "mm"
        });
      }

      return values;
    },
    qtyValues: function () {
      let values = [];
      let i = 10;
      while (i <= 5000) {
        values.push({
          val: i,
          txt: i + " " + this.item.getUnit(),
        })
        if ( i < 50 ) {
          i += 5;
        }
        else if ( i < 100 ) {
          i += 10;
        }
        else if (i < 500) {
          i += 50;
        } else if (i < 1000) {
          i += 100;
        } else {
          i += 1000;
        }
      }

      return values;
    },
    yesNoValues: function () {
      return [{
        val: true,
        txt: this.$t('general.yes'),
      },{
        val: false,
        txt: this.$t('general.no')
      }];
    }
  },
  methods: {
    isFormReady: function () {
      return !(
          this.v$.offer.product.product_id.$error ||
          this.v$.offer.product.qty.$error ||
          this.v$.offer.product.min_qty.$error ||
          this.v$.offer.product.price.$error ||
          this.v$.offer.product.valid_from.$error ||
          this.v$.offer.product.valid_to.$error ||
          this.v$.offer.product.details.delivery_areas.$error ||
          this.v$.offer.product.details.diameter_from.$error ||
          this.v$.offer.product.details.diameter_to.$error ||
          this.v$.image.$error
      );
    },
    getProductName: function (productId) {
      let product = this.getProduct(productId);
      if (null === product) {
        return this.$t("general.product.not_found");
      }

      return product.display_name ?? product.name;
    },
    resetInputs: function() {
      this.v$.offer.product.price.$reset();
      this.v$.offer.product.qty.$reset();
      this.v$.offer.product.min_qty.$reset();
      this.v$.offer.product.product_id.$reset();
      this.v$.offer.product.valid_from.$reset();
      this.v$.offer.product.valid_to.$reset();
      this.v$.offer.product.details.delivery_areas.$reset();
      this.v$.offer.product.details.diameter_from.$reset();
      this.v$.offer.product.details.diameter_to.$reset();
      this.v$.image.$reset();
    },
    validateInputs: function ()
    {
      this.v$.offer.product.product_id.$touch();
      this.v$.offer.product.qty.$touch();
      this.v$.offer.product.min_qty.$touch();
      this.v$.offer.product.price.$touch();
      this.v$.offer.product.valid_from.$touch();
      this.v$.offer.product.valid_to.$touch();
      this.v$.offer.product.details.delivery_areas.$touch();
      this.v$.offer.product.details.diameter_from.$touch();
      this.v$.offer.product.details.diameter_to.$touch();
      this.v$.image.$touch();
    },
    updateAvailability: function () {
      if (this.startAt) {
        this.offer.product.valid_from = [this.startAt.getFullYear(), this.startAt.getMonth() + 1, this.startAt.getDate()].join("-");
      } else {
        this.offer.product.valid_from = null;
      }

      if (this.endAt) {
        this.offer.product.valid_to = [this.endAt.getFullYear(), this.endAt.getMonth() + 1, this.endAt.getDate()].join("-");
      } else {
        this.offer.product.valid_to = null;
      }

      this.v$.offer.product.valid_from.$touch();
      this.v$.offer.product.valid_to.$touch();
    },
    updateProduct: function (item, productId) {
      let product = this.getProduct(productId);
      item.product.product_id = productId;
      if (null !== product) {
        item.product.unit = product.unit;
      }
      this.v$.offer.product.product_id.$touch();
    },
    updateQty: function (item, qty) {
      item.product.qty = qty;
      this.v$.offer.product.qty.$touch();
    },
    updateMinQty: function (item, qty) {
      item.product.min_qty = qty;
      this.v$.offer.product.qty.$reset();
      this.v$.offer.product.min_qty.$touch();
      this.v$.offer.product.qty.$touch();
    },
    updatePrice: function (item, price) {
      item.product.price = price;
      this.v$.offer.product.price.$touch();
    },
    updatePacking: function (item, packing) {
      item.product.details.packing = packing;
    },
    updateDiameterFrom: function (item, diameter) {
      item.product.details.diameter_from = diameter;
      this.v$.offer.product.details.diameter_to.$reset();
      this.v$.offer.product.details.diameter_from.$touch();
      this.v$.offer.product.details.diameter_to.$touch();
    },
    updateDiameterTo: function (item, diameter) {
      item.product.details.diameter_to = diameter;
      this.v$.offer.product.details.diameter_to.$touch();
    },
    updateCertificates: function (item, certificates) {
      item.product.details.certificates = certificates;
    },
    updateDeliveryCooler: function (item, hasDeliveryCooler) {
      item.product.details.has_delivery_cooler = hasDeliveryCooler;
    },
    updateDeliveryArea: function (item, area) {
      if (item.product.details.delivery_areas.includes(area)) {
        item.product.details.delivery_areas = item.product.details.delivery_areas.filter(function (existingArea) {
          return existingArea !== area;
        });
      } else {
        item.product.details.delivery_areas.push(area);
      }
      this.v$.offer.product.details.delivery_areas.$touch();
    },
    updateEuroPallet: function (item, hasEuroPallet) {
      item.product.details.has_delivery_euro_pallet = hasEuroPallet;
    },

    update: function ()
    {
      this.offer.product.price = parseFloat(this.offer.product.price);
      this.updateAvailability();
      this.resetInputs();
      this.resetNotifications();
      this.$nextTick(function () {
        this.doUpdate();
      });
    },
    doUpdate: function () {
      this.validateInputs();
      let that = this;
      let request;

      let data = {
        qty: this.offer.getQty(),
        min_qty: this.offer.getMinQty(),
        price: parseFloat(this.offer.getPrice()),
        valid_from: this.offer.getValidFrom(),
        valid_to: this.offer.getValidTo(),
        packing: this.offer.getPacking(),
        has_delivery_euro_pallet: this.offer.hasDeliveryEuroPallet(),
        has_delivery_cooler: this.offer.hasDeliveryCooler(),
        delivery_areas: this.offer.getDeliveryAreas(),
        product_diameter_from: this.offer.getDiameterFrom(),
        product_diameter_to: this.offer.getDiameterTo(),
        certificates: this.offer.getCertificates(),
      }

      if (!this.isFormReady()) {
        this.addWarningNotification("general.form_invalid");
        this.scrollToBottomOfModal('.en-gross');
        return;
      }

      this.isLoading = true;
      if (this.offer.isNew()) {
        data.product_id = this.offer.getProductId();
        request = this.axios.post(this.$store.state.config.getEnGrossProductsUri(this.farmerId), data);
      } else {
        request = this.axios.put(this.$store.state.config.getEnGrossProductUri(this.farmerId, this.offer.getId()), data);
      }

      request.then(
          async (response) => {
            let content = response.data;
            let isUploadSuccess = true;
            if (this.image instanceof File) {
              isUploadSuccess = await that.uploadImage(content.data.id);
            }

            if (isUploadSuccess) {
              that.$emit('update');
            } else {
              that.addWarningNotification("general.upload_failed");
            }
          }
      ).catch(
          error => {
            that.addErrorNotification("general.failed");
            console.error(error);
          }
      ).finally(() => {
        if (that.canShowNotification) {
          that.scrollToNotificationBox();
        }
        that.isLoading = false;
      });
    },
    uploadImage: function (offerId)
    {
      let formData = new FormData();
      formData.append('image', this.image);
      return this.axios.post(this.$store.state.config.getEnGrossProductImageUploadUri(this.farmerId, offerId), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
          return true;
      }).catch((error) => {
          console.warn(error);
          return false;
      });
    },
    getProduct: function (productId)
    {
      let filtered = this.products.filter((product => product.id === productId));
      return filtered.length > 0 ? filtered[0] : null;
    }
  }
}
</script>

<style scoped>
.input-errors {
  font-size: .75rem;
  text-align: right;
  width: 100%;
  padding: 0 10px;
}
</style>
