export default class EnGrossProduct
{
    product;

    constructor(product = {
        currency: "RON",
        unit: 'kg',
        qty: 0,
        min_qty: 0,
        price: 0,
        details: {
            packing: 0,
            has_delivery_cooler: false,
            has_delivery_euro_pallet: false,
            certificates: "",
            diameter_to: 0,
            diameter_from: 0,
            delivery_areas: ['max_100km']
        }
    }) {
        this.product = product;
    }

    getId()
    {
        return this.product.id;
    }

    getProductId()
    {
        return this.product.product_id;
    }

    getFarmerName()
    {
        return this.product.farmer_name;
    }

    getSku()
    {
        return this.product.details.sku;
    }

    getName()
    {
        return this.product.details.name;
    }

    getQty()
    {
        return this.product.qty;
    }

    getMinQty()
    {
        return this.product.min_qty;
    }

    getPrice()
    {
        return this.product.price;
    }

    getValidFrom()
    {
        return this.product.valid_from;
    }

    getValidTo()
    {
        return this.product.valid_to;
    }

    getPacking()
    {
        return parseFloat(""+this.product.details.packing);
    }

    hasDeliveryEuroPallet()
    {
        return !!this.product.details.has_delivery_euro_pallet;
    }

    hasDeliveryCooler()
    {
        return !!this.product.details.has_delivery_cooler;
    }

    getDeliveryAreas()
    {
        return this.product.details.delivery_areas;
    }

    getDiameterFrom()
    {
        return this.product.details.diameter_from;
    }

    getDiameterTo()
    {
        return this.product.details.diameter_to;
    }

    getCertificates()
    {
        return this.product.details.certificates;
    }

    getImage()
    {
        return this.product.details.image || null;
    }

    getCreatedAt()
    {
        return this.product.created_at;
    }

    getUpdatedAt()
    {
        return this.product.updated_at;
    }

    getCurrency()
    {
        return this.product.currency;
    }

    getUnit()
    {
        return this.product.unit;
    }

    isNew()
    {
        return this.product && !this.product.id;
    }
}
