/**
 * This is a workaround for datepickers having incorrect position
 * when they are inside modals, because of the overflow-y scroll
 */
export default {
    data() {
        return {
            modalBody: null,
        }
    },
    mounted() {
        this.modalBody = document.querySelector(".modal-card-body");

        if (this.modalBody) {
            this.modalBody.addEventListener("scroll", this.scrollHandler);
        }
    },

    destroy() {
        if (this.modalBody) {
            this.modalBody.removeEventListener("scroll", this.scrollHandler);
        }
    },

    methods: {
        scrollHandler(event) {
            const scrollTop = event.target.scrollTop;

            // Pull datepickers back up with negative margin by the scroll top amount
            document.querySelectorAll(".modal-card-body .v3dp-datepicker__calendar")
                .forEach((datePicker) => {
                    datePicker.style.marginTop = `-${scrollTop}px`;
                });
        }
    }
}