<template>
  <div class="sticky-bottom position-fixed w-100 left-0 bg-light border-top border-top-grey-300"  >
    <div class="page-content content-boxed px-3">
    <slot>
    </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "StickyBottom",
  props: {

  },
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.sticky-bottom {
  bottom: 50px;
}
</style>
