<template>
  <div>
    <label class="wrapper flex items-center">
      <span v-if="label" class="mr-2" v-html="$t(label)"></span>
      <input v-bind:disabled="disabled" class="checkbox" :checked="isChecked" type="checkbox" @change="$emit('update', $event.target.checked)"/>
      <span class="checkmark"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckboxInput",
  emits: ['update'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isChecked: function () {
      return this.value == true;
    }
  },
  methods: {
  }
}
</script>

<style scoped>
/* Customize the label (the wrapper) */
.wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 2px;
  background-color: #eee;
  border: 1px solid #ccc;
}
/* On mouse-over, add a grey background color */
.wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.wrapper input:checked ~ .checkmark {
  background-color: #17393a;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.wrapper .checkmark:after {
  left: 7px;
  top: 0px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
