<template>
  <Card :class="{'verde small-padding': !isScreenLgWidth }" :with-header="false" :with-footer="false">
    <template v-slot:default>
      <div class="row">
        <div class="col-9">
          <div class="row mb-1">
            <div class="col-12">
              <h3 v-bind:class="{'verde': !isScreenLgWidth}" class="font-weight-semibold m-0"> {{ item.getName() }} - <span><Price :amount="item.getPrice()" :currency="item.getCurrency()" />&nbsp;/&nbsp;{{item.getUnit()}}</span></h3>
            </div>
          </div>
          <div class="row text-danger">
            <div class="col-12">
              <span class="d-flex">{{$t('frontoffice.farmers.offers.en-gross.item.qty')}}: <span><Qty element-style="mr-0 ml-2 d-inline" :amount="item.getQty()" :unit="item.getUnit()" /></span></span>
            </div>
          </div>
          <div class="row text-danger">
            <div class="col-12">
              <span class="d-flex">{{$t('frontoffice.farmers.offers.en-gross.item.availability')}}: <span><Date class="m-0 p-0" :date="item.getValidFrom()" /> - <Date class="m-0 p-0" :date="item.getValidTo()" /></span></span>
            </div>
          </div>
        </div>
        <div class="col-3">
          <Button class="d-block d-md-flex mr-auto ml-auto mt-2 w-md-100" :type="updateButtonIcon" :name="updateButtonName" @click="updateItem(item)" />
          <Button class="d-block d-md-flex mr-auto ml-auto mt-2 w-md-100" :type="deleteButtonIcon" :name="deleteButtonName" @click="deleteItem(item)" />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import {NumbersMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import Price from "@/components/elements/Price";
import Qty from "@/components/elements/Qty";
import Date from "@/components/elements/Date";
import EmittedEvent from "@/entities/EmittedEvent";

export default {
  name: "EnGrossItemCard",
  emits: ['update', 'delete'],
  mixins: [NumbersMixin, ScreenSizeMixin],
  components: {Date, Qty, Price, Card, Button},
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    updateButtonName: function () {
      let name = 'frontoffice.farmers.offers.en-gross.button.update';
      if (!this.isScreenLgWidth) {
        name = '';
      }

      return name;
    },
    deleteButtonName: function () {
      let name = 'frontoffice.farmers.offers.en-gross.button.delete';
      if (!this.isScreenLgWidth) {
        name = '';
      }

      return name;
    },
    updateButtonIcon: function () {
      if (this.isScreenLgWidth) {
        return 'edit_labeled';
      }

      return "edit";
    },
    deleteButtonIcon: function () {
      if (this.isScreenLgWidth) {
        return 'delete_labeled';
      }

      return 'delete';
    }
  },
  methods: {
    updateItem: function (item) {
      this.$emit("update", item);
    },
    deleteItem: function (item) {
      this.$emit("delete", new EmittedEvent("frontoffice.farmers.offers.en-gross.confirmation.delete_item", item));
    },
  }
}
</script>

<style scoped>
h3.verde {
  font-size: 1em;
}
span.d-flex.verde {
  font-size: 0.8em;
}
</style>
